<template>
    <b-card
        :title="card.projectName"
        :img-src="card.websiteImageUrl"
        :img-alt="card.projectName"
        img-top
        tag="article"
        class="mr-5 card-shadow"
    >
        <b-card-text v-if="card.websiteImageUrl === ''" class="mb-3">
            <h1 class="wip">COMING SOON</h1>
        </b-card-text>

        <b-card-text>
            {{ card.projectDetails }}
        </b-card-text>

        <div class="row justify-content-center">
            <a
                v-if="card.websiteImageUrl !== ''"
                :href="card.url"
                target="_blank"
            >
                <b-button
                    class="mr-3"
                    variant="secondary"
                >
                        Visit Website
                </b-button>
            </a>

            <b-button
                :class="{'mt-4': card.websiteImageUrl === ''}"
                href="#"
                variant="primary"
                @click="navigate(card.routeName)"
            >
                More Details
            </b-button>
        </div>
    </b-card>
</template>

<script>
    export default {
        name: 'CarouselCard',
        props: {
            card: {
                type: Object,
                required: true
            }
        },
        methods: {
            async navigate(routeName) {
                await this.$router.push({name: routeName});
            },
        }
    }
</script>

<style
    lang="sass"
    scoped
>
    @import "/src/custom"

    .card-img-top
        min-height: 400px !important

    .card-shadow
        box-shadow: 8px 10px 25px darkgray

    .wip
        color: $danger
        font-size: 150px
        text-align: center
</style>
