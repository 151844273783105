<template>
    <b-row>
        <BCarousel
            id="carousel-1"
            :interval="0"
            controls
            background="#D8DFE9"
        >
            <CarouselSlide
                v-for="(slide, index) in projects"
                :key="index"
                :slide-content="slide"
            />
        </BCarousel>
    </b-row>
</template>

<script>
    import {mapState} from 'vuex';
    import CarouselSlide from '@/components/CarouselSlide'

    export default {
        name: 'ProjectsCarousel',
        components: {
            CarouselSlide
        },
        computed: {
            ...mapState([
                'projects',
            ])
        },
    }
</script>
