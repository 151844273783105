<template>
    <BCarouselSlide>
        <template #img>
            <b-row class="mt-4 mb-4 ml-4 h-100 justify-content-center">
                <CarouselCard
                    v-for="(card, index) in slideContent"
                    :key="index"
                    :card="card"
                    class="col-lg-5 col-md-6 col-sm-6 "
                />
            </b-row>
        </template>
    </BCarouselSlide>
</template>

<script>
    import CarouselCard from '@/components/CarouselCard';

    export default {
        name: 'CarouselSlide',
        components: {
            CarouselCard,
        },
        props: {
            slideContent: {
                type: Array,
                default: () => ([]),
                required: false
            }
        },
        methods: {
            test() {
                console.log('howdy')
            }
        }
    }
</script>
