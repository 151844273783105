<template>
    <div class="row footer text-center text-md-left mt-auto">
        <div class="col-md-4 col-12">
            <div>
                <router-link to="/">
                    <h3>David Bane</h3>
                </router-link>
            </div>

            <div class="mt-md-3">
                <p class="footer-links">
                    <b-navbar-nav class="order-links">
                        <router-link to="/">
                            <b-nav-item href="/">Home</b-nav-item>
                        </router-link>
                        ·
                        <router-link to="/about">
                            <b-nav-item href="/about">About</b-nav-item>
                        </router-link>
                        ·
                        <router-link to="/zauvu">
                            <b-nav-item href="/zauvu">Projects</b-nav-item>
                        </router-link>
                    </b-navbar-nav>
                </p>
            </div>
        </div>

        <div class="col-lg-4 col-md-5 col-12 mb-md-4 footer-center">
            <div class="hor-center text-center">
                <div class="d-sm-block">
                    <FontAwesomeIcon :icon="[ 'fas', 'map-marker-alt' ]"/>
                    <p><a href="http://maps.google.com/?q=Omaha, Nebraska" target="_blank">Omaha, Nebraska</a></p>
                </div>

                <div class="d-sm-block mt-2">
                    <FontAwesomeIcon :icon="[ 'fas', 'envelope' ]"/>
                    <p><a href="mailto:dtbane18@gmail.com" target="_blank">dtbane18@gmail.com</a></p>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-4 col-md-3">
            <div class="float-md-right mr-lg-5">
                <div class="mt-3 mt-md-0">
                    <FontAwesomeIcon @click="$emit('openModal')" :icon="[ 'fas', 'id-badge' ]" class="nav"/>

                    <a href="https://www.linkedin.com/in/david-bane-79a959129/" target="_blank">
                        <FontAwesomeIcon :icon="[ 'fab', 'linkedin' ]" class="nav"/>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    import {library} from '@fortawesome/fontawesome-svg-core';
    import {
        faEnvelope,
        faIdBadge,
        faMapMarkerAlt
    } from '@fortawesome/free-solid-svg-icons';
    import {
        faLinkedin
    } from '@fortawesome/free-brands-svg-icons';

    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

    library.add(
        faEnvelope,
        faIdBadge,
        faLinkedin,
        faMapMarkerAlt,
    );

    Vue.component('FontAwesomeIcon', FontAwesomeIcon);

    export default {
        name: 'Footer'
    }
</script>

<style
    lang="sass"
    scoped
>
    @import "/src/custom"

    a
        text-decoration: none !important

    .order-links
        display: inline-block
        color: $primary-grey

    .footer
        background-color: $primary
        font: bold 16px sans-serif
        padding: 50px 50px
        vertical-align: top

        h3
            color: $primary-grey
            &:hover
                color: $primary-gold

        .footer-links a
            color: $primary-grey !important
            display: inline-block
            &:hover
                color: $primary-gold !important

        .hor-center
            display: table
            margin: 0 auto

        .svg-inline--fa
            color: $primary-grey
            width: 22px
            height: 22px
            margin: 10px 15px
            vertical-align: middle

            &.nav
                display: inline-block
                width: 50px
                height: 50px
                &:hover
                    color: $primary-gold
                    cursor: pointer

        .footer-center
            p
                display: inline-block
            a
                color: $primary-grey
                &:hover
                    color: $primary-gold
</style>
