import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
  },
  {
    path: '/september-thomas',
    name: 'September Thomas',
    component: () => import(/* webpackChunkName: "september-thomas" */ './views/SeptemberThomas.vue')
  },
  {
    path: '/zauvu',
    name: 'Zauvu',
    component: () => import(/* webpackChunkName: "zauvu" */ './views/Zauvu.vue')
  },
  {
    path: '/quick-buck',
    name: 'Quick Buck',
    component: () => import(/* webpackChunkName: "quick-buck" */ './views/QuickBuck.vue')
  },
  {
    path: '/bane-lib',
    name: 'Bane Lib',
    component: () => import(/* webpackChunkName: "portfolio" */ './views/Portfolio.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return {x: 0, y: 0}
  }
})

export default router
