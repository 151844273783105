<template>
    <div class="row justify-content-center mb-5 mt-4">
        <div class="accordion col-12 col-lg-8" role="tablist">
            <BCard
                class="mb-1"
                no-body
            >
                <BCardHeader
                    header-tag="header"
                    class="p-1"
                    role="tab"
                >
                    <BButton
                        block
                        v-b-toggle.accordion-1
                        variant="primary"
                    >
                        Skills
                    </BButton>
                </BCardHeader>

                <BCollapse
                    id="accordion-1"
                    visible
                    accordion="my-accordion"
                    role="tabpanel"
                >
                    <div class="row">
                        <div class="col-md-4 col-12 text-center">
                            <h4>Frameworks</h4>
                            <b-list-group class="d-none d-md-block">
                                <b-list-group-item>Vue.js</b-list-group-item>
                                <b-list-group-item>Django</b-list-group-item>
                                <b-list-group-item>React</b-list-group-item>
                                <b-list-group-item>GraphQl</b-list-group-item>
                                <b-list-group-item>Jest</b-list-group-item>
                                <b-list-group-item>Cypress</b-list-group-item>
                            </b-list-group>

                            <p class="d-block d-md-none">Vue.js, Django, React, GraphQl</p>
                        </div>

                        <div class="col-md-4 col-12 text-center">
                            <h4>Languages</h4>
                            <b-list-group class="d-none d-md-block">
                                <b-list-group-item>JavaScript</b-list-group-item>
                                <b-list-group-item>Python</b-list-group-item>
                                <b-list-group-item>Sass</b-list-group-item>
                                <b-list-group-item>CSS</b-list-group-item>
                                <b-list-group-item>HTML</b-list-group-item>   
                            </b-list-group>

                            <p class="d-block d-md-none">JavaScript, Python, Sass, CSS, HTML, Jest, Cypress</p>
                        </div>

                        <div class="col-md-4 col-12 text-center">
                            <h4>Tools</h4>
                            <b-list-group class="d-none d-md-block">
                                <b-list-group-item>Docker</b-list-group-item>
                                <b-list-group-item>Gitlab</b-list-group-item>
                                <b-list-group-item>AWS</b-list-group-item>
                                <b-list-group-item>Postman</b-list-group-item>
                                <b-list-group-item>Figma</b-list-group-item>
                            </b-list-group>

                            <p class="d-block d-md-none">Docker, Gitlab, AWS, Postman, Figma</p>
                        </div>
                    </div>
                </BCollapse>
            </BCard>

            <BCard
                class="mb-1"
                no-body
            >
                <BCardHeader
                    class="p-1"
                    header-tag="header"
                    role="tab"
                >
                    <BButton
                        block
                        v-b-toggle.accordion-2
                        variant="primary"
                    >
                        Work and Education
                    </BButton>
                </BCardHeader>

                <BCollapse
                    id="accordion-2"
                    accordion="my-accordion"
                    role="tabpanel"
                    class="row justify-content-center"
                >
                    <b-list-group class="text-left">
                        <b-list-group-item>
                            <font-awesome-icon :icon="[ 'fas', 'code' ]" class="fa-2x mr-2"/>
                            Full Stack Web Developer I @ IMT Insurance - Aug 2019 to Present
                        </b-list-group-item>
                        
                        <b-list-group-item>
                            <font-awesome-icon :icon="[ 'fas', 'code' ]" class="fa-2x mr-2"/>
                            Web Development Intern @ IMT Insurance - May 2018 to Aug 2019
                        </b-list-group-item>
                        
                        <b-list-group-item>
                            <font-awesome-icon :icon="[ 'fas', 'graduation-cap' ]" class="fa-2x mr-2"/>
                            Bachelors in Software Engineering from Iowa State University - 2019
                        </b-list-group-item>
                    </b-list-group>
                </BCollapse>
            </BCard>

            <BCard
                class="mb-1"
                no-body
            >
                <BCardHeader
                    class="p-1"
                    header-tag="header"
                    role="tab"
                >
                    <BButton
                        block
                        v-b-toggle.accordion-3
                        variant="primary"
                    >
                        Collaboration
                    </BButton>
                </BCardHeader>

                <BCollapse
                    id="accordion-3"
                    accordion="my-accordion"
                    role="tabpanel"
                    class="row justify-content-center"
                >
                    <b-list-group class="text-left">
                        <b-list-group-item class="float-left">
                            <font-awesome-icon :icon="[ 'fas', 'certificate' ]" class="fa-2x mr-2"/>
                            Certified Scrum Master.
                        </b-list-group-item>

                        <b-list-group-item class="float-left">
                            <font-awesome-icon :icon="[ 'fab', 'gitlab' ]" class="fa-2x mr-2"/>
                            Development experience in both Scrum and Kanban based environments.
                        </b-list-group-item>

                        <b-list-group-item>
                            <font-awesome-icon :icon="[ 'fas', 'handshake' ]" class="fa-2x mr-2"/>
                            Continuous work with interdepartmental requirement solicitation.
                        </b-list-group-item>

                        <b-list-group-item>
                            <font-awesome-icon :icon="[ 'fas', 'users' ]" class="fa-2x mr-2"/>
                            Experience organizing project work and holding team presentations.
                        </b-list-group-item>
                    </b-list-group>
                </BCollapse>
            </BCard>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    import {library} from "@fortawesome/fontawesome-svg-core";
    import {
        faCertificate,
        faCode,
        faFileCode,
        faGraduationCap,
        faHandshake,
        faUsers
    } from '@fortawesome/free-solid-svg-icons';

    import {
        faGitlab
    } from '@fortawesome/free-brands-svg-icons';


    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

    library.add(
        faCertificate,
        faCode,
        faFileCode,
        faGitlab,
        faGraduationCap,
        faHandshake,
        faUsers
    );

    Vue.component('font-awesome-icon', FontAwesomeIcon);

    export default {
        name: 'SkillsOverview',
        components: {}
    }
</script>

<style
    lang="sass"
    scoped
>
    @import "/src/custom"

    .list-group-item
        border: none
        background-color: #F5F5F5
        &:hover
            color: $secondary-green

            .fa-2x
                color: $secondary-green

    .card
        border-color: $secondary-green
        background-color: #F5F5F5

    .card-header
        border-bottom: none
        background-color: #F5F5F5

    .fa-2x
        color: $primary-green
</style>
