<template>
    <BModal
        id="contact-modal"
        title="Contact Me"
        title-class="text-center"
        hide-footer
        centered
        size="md"
    >
        <template #modal-title>
            <FontAwesomeIcon
                :icon="[ 'fas', 'user' ]"
                class="mr-2"
            />
            Contact Me
        </template>

        <p>Phone: <a href="tel:402-880-7960" target="_blank">402-880-7960</a></p>
        <p>Email: <a href="mailto:dtbane18@gmail.com" target="_blank">dtbane18@gmail.com</a></p>
        <p>Located in: <a href="http://maps.google.com/?q=Omaha, Nebraska" target="_blank">Omaha, Nebraska</a></p>
        <p>LinkedIn: <a href="https://www.linkedin.com/in/david-bane-79a959129/" target="_blank">View my Profile</a></p>
    </BModal>
</template>

<script>
    import Vue from 'vue';

    import {library} from "@fortawesome/fontawesome-svg-core";
    import {faUser} from '@fortawesome/free-solid-svg-icons';
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

    library.add(faUser);

    Vue.component('FontAwesomeIcon', FontAwesomeIcon);

    export default {
        name: 'ContactModal',
    }
</script>

<style
    lang="sass"
    scoped
>
    @import "/src/custom"
    a
        text-decoration: none !important
        &:hover
            color: $secondary-green
</style>
