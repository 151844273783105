import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const state = () => {
    return {
        projects: {
            slide1: [
                {
                    'projectName': 'Zauvu',
                    'projectDetails': 'Making travel planning easier by combining all your travel planning needs.',
                    'routeName': 'Zauvu',
                    'websiteImageUrl': 'https://banedev.s3.us-east-2.amazonaws.com/Portfolio/dashView2.png',
                    'url': 'https://www.zauvu.com/'
                },
                {
                    'projectName': 'September Thomas',
                    'projectDetails': 'Book portfolio and blog for author September Thomas.',
                    'routeName': 'September Thomas',
                    'websiteImageUrl': 'https://banedev.s3.us-east-2.amazonaws.com/Portfolio/STBlue.png',
                    'url': 'https://www.septemberthomas.com/'
                },
            ],
            slide2: [
                {
                    'projectName': 'Quick Buck',
                    'projectDetails': 'An entirely serious website that was not designed to be a joke.',
                    'routeName': 'Quick Buck',
                    'websiteImageUrl': 'https://banedev.s3.us-east-2.amazonaws.com/Portfolio/QuickBuck.jpg',
                    'url': 'https://howtomakeaquickbuck.com/'
                },
                {
                    'projectName': 'Bane Library Package',
                    'projectDetails': 'A package for hosting some self made components.',
                    'routeName': 'Bane Lib',
                    'websiteImageUrl': 'https://banedev.s3.us-east-2.amazonaws.com/Portfolio/lib-package.png',
                    'url': 'https://www.npmjs.com/package/bane-lib'
                }
            ]
        },
    };
};

export default new Vuex.Store({
    state: state(),
});
