<template>
  <div>
      <BRow align-v="center">
          <div class="d-none d-xl-block offset-lg-1 col-md-5 col-12 mb-n5">
              <div class="image-container">
                  <BImg
                      fluid
                      class="image"
                      src="https://banedev.s3.us-east-2.amazonaws.com/Portfolio/transparentDev2Comp2.png"
                      v-b-popover.hover.left="'Hey look, its me'"
                  />
              </div>
          </div>

          <div class="d-none d-md-block d-xl-none col-md-6 col-12 mb-n5">
              <div class="image-med-container">
                  <BImg
                      fluid
                      class="image"
                      src="https://banedev.s3.us-east-2.amazonaws.com/Portfolio/transparentDev2Comp2.png"
                      v-b-popover.hover.left="'Hey look, its me'"
                  />
              </div>
          </div>

          <div class="col-12 col-md-5">
              <div class="text-center">
                  <BImg
                      fluid
                      v-b-popover.hover.top="'Thats my name'"
                      :src="'https://banedev.s3.us-east-2.amazonaws.com/Portfolio/home.png'"
                  />
              </div>

              <h2>Full Stack Web Developer II</h2>
              <h2>Scrum Master</h2>
              <h2>Software Engineering Graduate</h2>

              <div class="d-block d-md-none small-image-container">
                  <BImg
                      class="image"
                      src="https://banedev.s3.us-east-2.amazonaws.com/Portfolio/transparentDev2Comp2.png"
                      v-b-popover.hover.left="'Hey look, its me'"
                  />
              </div>

              <h3>
                  <RouterLink :to="{name: 'About'}">
                      Read my story
                      <FontAwesomeIcon
                          :icon="[ 'fas', 'hand-point-right' ]"
                      />
                  </RouterLink>
              </h3>
          </div>
      </BRow>

      <hr class="d-block d-lg-none"/>

      <BRow class="d-block d-lg-none justify-content-center">
          <h2>Projects</h2>
          <div class="col-10 offset-2 offset-md-4 col-md-8">
              <h4>
                  <router-link
                      class="routerLink"
                      :to="{name: 'September Thomas'}"
                  >
                      <FontAwesomeIcon :icon="[ 'fas', 'book' ]"/>
                      September Thomas
                  </router-link>
              </h4>

              <h4>
                  <router-link
                      class="routerLink"
                      :to="{name: 'Zauvu'}"
                  >
                      <FontAwesomeIcon :icon="[ 'fas', 'plane' ]"/>
                      Zauvu
                  </router-link>
              </h4>

              <h4>
                  <router-link
                      class="routerLink"
                      :to="{name: 'Quick Buck'}"
                  >
                      <FontAwesomeIcon :icon="[ 'fas', 'money-bill-wave' ]"/>
                      Quick Buck
                  </router-link>
              </h4>

              <h4>
                  <router-link
                      class="routerLink"
                      :to="{name: 'Bane Lib'}"
                  >
                      <FontAwesomeIcon :icon="[ 'fas', 'code' ]"/>
                      Bane Library
                  </router-link>
              </h4>
          </div>
      </BRow>

      <ProjectsCarousel class="d-none d-lg-block mt-5"/>

      <hr class="d-block d-lg-none"/>

      <SkillsOverview />
  </div>
</template>

<script>
    import Vue from 'vue';

    import {library} from "@fortawesome/fontawesome-svg-core";

    import {
        faBook,
        faCode,
        faHandPointRight,
        faMoneyBillWave,
        faPlane,
    } from '@fortawesome/free-solid-svg-icons';
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

    library.add(
        faBook,
        faCode,
        faHandPointRight,
        faMoneyBillWave,
        faPlane,
    );

    Vue.component('FontAwesomeIcon', FontAwesomeIcon);

    import ProjectsCarousel from '@/components/ProjectsCarousel';
    import SkillsOverview from '@/components/SkillsOverview';

    export default {
        name: 'Home',
        components: {
            ProjectsCarousel,
            SkillsOverview,
        }
    }
</script>

<style
    lang="sass"
    scoped
>
    @import "../custom"

    .small-image-container
        width: 325px
        margin: auto

        .image
            max-height: 100%
            max-width: 100%
            border-radius: 20px

        h1
            font-size: 100px !important

    .image-med-container
        width: 400px
        margin: auto

        .image
            max-height: 100%
            max-width: 100%
            border-radius: 20px

    h1
        font-size: 100px !important

    .image-container
        width: 700px !important
        margin: auto

        .image
            max-height: 100%
            max-width: 100%
            border-radius: 20px

    h1
        font-size: 70px
        text-align: center

    h2
        font-size: 30px
        margin-top: 20px
        text-align: center
    h3
        margin-top: 30px
        text-align: center
        a
            text-decoration: none !important
            &:hover
                color: $secondary-green

    h4
        text-align: left
        color: $primary-green
</style>
