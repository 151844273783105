<template>
    <b-navbar
        toggleable="lg"
        type="dark"
        variant="primary"
    >
        <router-link to="/">
            <b-navbar-brand class="logo" href="/">
                    <BImg
                        class="brand-no-hover ml-3"
                        width="48px"
                        src="https://banedev.s3.us-east-2.amazonaws.com/Portfolio/BaneDevSilver.png"
                    />

                    <BImg
                        class="brand-hover ml-3 mt-n2 mb-n1"
                        width="55px"
                        src="https://banedev.s3.us-east-2.amazonaws.com/Portfolio/BaneDevGold.png"
                    />
            </b-navbar-brand>
        </router-link>

        <p class="version">v{{version}}</p>

        <b-navbar-toggle target="nav-collapse"/>

        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
                <router-link
                    class="routerLink"
                    to="/"
                >
                    <b-nav-item href="/">Home</b-nav-item>
                </router-link>

                <router-link
                    class="routerLink"
                    to="/about"
                >
                    <b-nav-item href="/about">About</b-nav-item>
                </router-link>

                <BNavItemDropdown
                    text="Projects"
                    right
                >
                    <b-dropdown-item
                        class="routerLink"
                        @click="navigate('Zauvu')"
                    >
                        <FontAwesomeIcon :icon="[ 'fas', 'plane' ]" class="mr-3 mr-lg-1"/>
                        Zauvu
                    </b-dropdown-item>

                    <b-dropdown-item
                        class="routerLink"
                        @click="navigate('September Thomas')"
                    >
                        <FontAwesomeIcon :icon="[ 'fas', 'book' ]" class="mr-3 mr-lg-1"/>
                        September Thomas
                    </b-dropdown-item>

                    <b-dropdown-item
                        class="routerLink"
                        @click="navigate('Quick Buck')"
                    >
                        <FontAwesomeIcon :icon="[ 'fas', 'money-bill-wave' ]" class="mr-3 mr-lg-1"/>
                        Quick Buck
                    </b-dropdown-item>

                    <b-dropdown-item
                        class="routerLink"
                        @click="navigate('Bane Lib')"
                    >
                        <FontAwesomeIcon :icon="[ 'fas', 'code' ]" class="mr-3 mr-lg-1"/>
                        Bane Lib
                    </b-dropdown-item>
                </BNavItemDropdown>

                <b-navbar-nav class="socials">
                    <b-nav-item
                        @click="$emit('openModal')"
                    >
                        <FontAwesomeIcon :icon="[ 'fas', 'id-badge' ]" class="mr-3 mr-lg-0 fa-2x"/>
                    </b-nav-item>

                    <b-nav-item
                        href="https://www.linkedin.com/in/david-bane-79a959129/"
                        target="_blank"
                    >
                        <FontAwesomeIcon :icon="[ 'fab', 'linkedin' ]" class="mr-3 mr-lg-0 fa-2x"/>
                    </b-nav-item>
                </b-navbar-nav>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
    import {version} from '../../package.json'

    import {library} from '@fortawesome/fontawesome-svg-core';

    import {
        faLinkedin
    } from '@fortawesome/free-brands-svg-icons';

    import {
        faBook,
        faCode,
        faIdBadge,
        faMoneyBillWave,
        faPlane,
    } from '@fortawesome/free-solid-svg-icons';

    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

    import Vue from "vue";

    library.add(
        faBook,
        faCode,
        faIdBadge,
        faLinkedin,
        faMoneyBillWave,
        faPlane,
    );

    Vue.component('FontAwesomeIcon', FontAwesomeIcon);

    export default {
        name: 'NavBar',
        data() {
            return {
                version: version,
            }
        },
        methods: {
            async navigate(routeName) {
                await this.$router.push({name: routeName});
            },
        }
    }
</script>

<style
    scoped
    lang="sass"
>
    @import "../custom"

    a
        text-decoration: none

    .routerLink
        text-decoration: none

    .socials
        flex-direction: row !important

    .logo
        display: inline-block
        &:hover
            .brand-hover
                display: inline-block
                margin-bottom: 2px
            .brand-no-hover
                display: none
        .brand-hover
            display: none
    .version
        color: $primary-grey

    .routerLink
        text-decoration: none !important
</style>
