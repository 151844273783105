<template>
    <div id="app">
        <NavBar @openModal="openModal"/>

        <main class="container-fluid d-flex pt-3 flex-column min-vh-100">
            <RouterView />
            <Footer @openModal="openModal"/>
            <ContactModal />
        </main>
    </div>
</template>

<script>
    import ContactModal from '@/components/ContactModal.vue';
    import Footer from '@/components/Footer.vue';
    import NavBar from '@/components/NavBar.vue';

    export default {
        name: 'App',
        components: {
            ContactModal,
            Footer,
            NavBar,
        },
        created() {
            console.log('( ^_^）o自自o（^_^ )');
            console.log('This website was designed and built by David Bane');
        },
        methods: {
            openModal() {
                this.$bvModal.show('contact-modal');
            }
        }

    }
</script>

<style lang="sass">
    @import "custom"

    #app
        background-color: #F5F5F5
</style>
